var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"my-card"},[_c('span',{staticClass:"my-card-title"},[_vm._v(_vm._s(_vm.$t("lastMoveHistory.modelName")))]),_c('div',{staticClass:"row"},[_c('DataLabelGroup',{attrs:{"className":'col-md-6',"value":_vm.lastPriceQuotationHistoryWorkInfoData.userCreatedData
          ? _vm.lastPriceQuotationHistoryWorkInfoData.userCreatedData
              .userNameCurrent
          : '',"title":_vm.$t('lastMoveHistory.userName'),"imgName":'users.svg'}}),_c('DataLabelGroup',{attrs:{"className":'col-md-6',"value":_vm.formateDateTimeLang(
          _vm.lastPriceQuotationHistoryWorkInfoData.creationDate
            ? _vm.lastPriceQuotationHistoryWorkInfoData.creationDate
            : '',
          _vm.lastPriceQuotationHistoryWorkInfoData.creationTime
            ? _vm.lastPriceQuotationHistoryWorkInfoData.creationTime
            : ''
        ),"title":_vm.$t('lastMoveHistory.dateTime'),"imgName":'dateAndTime.svg'}}),_c('DataLabelGroup',{attrs:{"className":'col-md-6',"value":_vm.lastPriceQuotationHistoryWorkInfoData.quotationDetailsHistoryWorkTypeNameCurrent,"title":_vm.$t('lastMoveHistory.status'),"imgName":'type.svg'}})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }