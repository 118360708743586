var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.isLoading)?_c('PreLoader'):_vm._e(),_c('CustomBottomSheet',{attrs:{"refName":'PriceQuotationJobOrderInfo',"size":"xl","headerText":_vm.$t('PriceQuotationJobOrders.data'),"headerIcon":_vm.priceQuotationJobOrder.icon}},[(_vm.exceptionMsg)?_c('ExceptionWithImg',{attrs:{"msg":_vm.exceptionMsg,"image":_vm.exceptionImg}}):[_c('div',{staticClass:"row"},[_c('DataLabelGroup',{attrs:{"className":'col-md-6',"value":_vm.priceQuotationJobOrder.fullCode,"title":_vm.$t('PriceQuotationJobOrders.code'),"imgName":'code.svg'}}),_c('DataLabelGroup',{attrs:{"className":'col-md-6',"value":_vm.priceQuotationJobOrder.priceQuotationJobOrderNameCurrent,"title":_vm.$t('general.name'),"imgName":'PriceQuotationJobOrders.svg'}}),_c('DataLabelGroup',{attrs:{"className":'col-md-12',"value":_vm.priceQuotationJobOrder.priceQuotationInfoData
              .priceQuotationNameCurrent,"title":_vm.$t('PriceQuotations.name'),"imgName":'PriceQuotations.svg'}}),_c('DataLabelGroup',{attrs:{"className":'col-md-6',"value":_vm.formateDateTimeLang(
              _vm.priceQuotationJobOrder.startWorkDate,
              _vm.priceQuotationJobOrder.startWorkTime
            ),"title":_vm.$t('PriceQuotationJobOrders.startWorkDateTime'),"imgName":'dateAndTime.svg'}}),_c('DataLabelGroup',{attrs:{"className":'col-md-6',"value":_vm.formateDateTimeLang(
              _vm.priceQuotationJobOrder.expectedFinishWorkDate,
              _vm.priceQuotationJobOrder.expectedFinishWorkTime
            ),"title":_vm.$t('PriceQuotationJobOrders.expectedFinishWorkDateTime'),"imgName":'dateAndTime.svg'}}),_c('DataLabelGroup',{attrs:{"className":'col-md-12',"value":_vm.priceQuotationJobOrder.priceQuotationInfoData.requestPlaceInfoData
              .placeNameCurrent,"title":_vm.$t('clientName'),"imgName":'man.svg'}})],1),_c('div',{staticClass:"my-card"},[_c('span',{staticClass:"my-card-title"},[_vm._v(_vm._s(_vm.$t("JobOrderStages.modelName")))]),(_vm.showActions && _vm.checkPrivilege(_vm.hasJobOrderStagesOfWorkAdd()))?_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12"},[_c('img',{staticClass:"icon-lg",attrs:{"src":require("@/assets/images/plus.svg"),"title":_vm.$t('JobOrderStages.add')},on:{"click":function($event){_vm.setJobOrderStagesOfWorkData(_vm.priceQuotationJobOrder);
                _vm.openBottomSheet('JobOrderStagesOfWorkAdd');}}})])]):_vm._e(),(
            _vm.priceQuotationJobOrder.priceQuotationJobOrderStagesOfWorkData
              .length
          )?[_c('table',{staticClass:"my-table"},[_c('thead',[_c('tr',[_c('th',{attrs:{"rowspan":"2"}},[_vm._v("#")]),_c('th',{attrs:{"colspan":"2"}},[_vm._v(_vm._s(_vm.$t("JobOrderStages.data")))]),(_vm.showActions)?_c('th',{attrs:{"rowspan":"2"}},[_c('i',{staticClass:"fas fa-sliders-h"})]):_vm._e()]),_c('tr',[_c('th',{staticClass:"cell-lg"},[_vm._v(_vm._s(_vm.$t("general.name")))]),_c('th',{staticClass:"cell-lg"},[_vm._v(_vm._s(_vm.$t("general.state")))])])]),_c('tbody',_vm._l((_vm.priceQuotationJobOrder.priceQuotationJobOrderStagesOfWorkData),function(stage,index){return _c('tr',{key:index},[_c('td',[_vm._v(_vm._s(++index))]),_c('td',[_vm._v(" "+_vm._s(_vm.isDataExist( stage.jobOrderStageSavedData.jobOrderStageNameCurrent ))+" ")]),_c('td',[_vm._v(" "+_vm._s(_vm.isDataExist( stage.jobOrderStagesOfWorkFinishData .stageFinishTypeNameCurrent ))+" ")]),(_vm.showActions)?_c('td',{staticClass:"fmenu-item-container"},[_c('FloatingMenu',[(
                        stage.jobOrderStagesOfWorkFinishData
                          .stageFinishTypeToken ==
                          _vm.TERMINATION_TYPES.NotFinished &&
                        _vm.checkPrivilege(_vm.hasJobOrderStagesOfWorkFinish())
                      )?_c('li',[_c('button',{attrs:{"title":_vm.$t('general.finish')},on:{"click":function($event){_vm.setJobOrderStagesOfWorkData(Object.assign({}, stage,
                            {priceQuotationJobOrderToken:
                              _vm.priceQuotationJobOrder.priceQuotationJobOrderToken}));
                          _vm.openBottomSheet('JobOrderStagesOfWorkFinish');}}},[_c('img',{attrs:{"src":require("@/assets/images/finish.svg")}})])]):_vm._e(),(
                        stage.jobOrderStagesOfWorkFinishData
                          .stageFinishTypeToken ==
                          _vm.TERMINATION_TYPES.Finished &&
                        _vm.checkPrivilege(_vm.hasJobOrderStagesOfWorkCancelFinish())
                      )?_c('li',[_c('button',{directives:[{name:"b-modal",rawName:"v-b-modal.JobOrderStagesOfWorkCancelFinish",modifiers:{"JobOrderStagesOfWorkCancelFinish":true}}],attrs:{"title":_vm.$t('general.cancelFinish')},on:{"click":function($event){return _vm.setJobOrderStagesOfWorkData(Object.assign({}, stage,
                            {priceQuotationJobOrderToken:
                              _vm.priceQuotationJobOrder.priceQuotationJobOrderToken}))}}},[_c('img',{attrs:{"src":require("@/assets/images/cancel.svg")}})])]):_vm._e(),(
                        _vm.checkPrivilege(_vm.hasJobOrderStagesOfWorkFinaleDelete())
                      )?_c('li',[_c('button',{directives:[{name:"b-modal",rawName:"v-b-modal.JobOrderStagesOfWorkDelete",modifiers:{"JobOrderStagesOfWorkDelete":true}}],attrs:{"title":_vm.$t('delete')},on:{"click":function($event){return _vm.setJobOrderStagesOfWorkData(Object.assign({}, stage,
                            {priceQuotationJobOrderToken:
                              _vm.priceQuotationJobOrder.priceQuotationJobOrderToken}))}}},[_c('img',{attrs:{"src":require("@/assets/images/trash.svg")}})])]):_vm._e()])],1):_vm._e()])}),0)])]:[_c('span',[_vm._v(_vm._s(_vm.$t("Login.noData")))])]],2)]],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }