var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{},[_c('form',{attrs:{"autocomplete":"off"}},[_c('div',{staticClass:"row"},[_c('CustomFileInput',{attrs:{"className":'col-12',"defaultImg":_vm.fullPathFileFromServer(
            _vm.priceQuotationJobOrder.priceQuotationJobOrderImagePath,
            _vm.priceQuotationJobOrder.defaultImg
          ),"deleteFileStatus":_vm.deleteFileStatus &&
          !_vm.priceQuotationJobOrder.priceQuotationJobOrderImageIsDefault &&
          _vm.checkPrivilege(_vm.hasPriceQuotationJobOrderDeleteImage())},on:{"changeValue":function($event){_vm.priceQuotationJobOrder.mediaFile = $event.file},"deleteFile":function($event){return _vm.$emit('deleteFile')}}})],1),_c('div',{staticClass:"my-card"},[_c('div',{staticClass:"row"},[_c('CustomInput',{attrs:{"className":'col-md-12',"id":(_vm.id + "-fullCode"),"value":_vm.priceQuotationJobOrder.fullCode,"title":_vm.$t('PriceQuotationJobOrders.code'),"imgName":'code.svg'},on:{"changeValue":function($event){_vm.priceQuotationJobOrder.fullCode = $event}}}),_c('DateTimePicker',{staticClass:"col-md-6",attrs:{"id":(_vm.id + "-startWorkDateTime"),"type":"dateTime","value":_vm.priceQuotationJobOrder.startWorkDateTime,"title":_vm.$t('PriceQuotationJobOrders.startWorkDateTime'),"language":_vm.language},on:{"changeValue":function($event){_vm.priceQuotationJobOrder.startWorkDateTime = $event.dateTime}}}),_c('DateTimePicker',{staticClass:"col-md-6",attrs:{"id":(_vm.id + "-expectedFinishWorkDateTime"),"type":"dateTime","value":_vm.priceQuotationJobOrder.expectedFinishWorkDateTime,"title":_vm.$t('PriceQuotationJobOrders.expectedFinishWorkDateTime'),"language":_vm.language},on:{"changeValue":function($event){_vm.priceQuotationJobOrder.expectedFinishWorkDateTime =
              $event.dateTime}}}),(!_vm.isEditing)?_c('CustomSelectBox',{attrs:{"className":'col-md-12',"id":(_vm.id + "-priceQuotationToken"),"value":_vm.priceQuotationJobOrder.priceQuotationToken,"options":_vm.priceQuotationTokenOptions,"title":_vm.$t('PriceQuotations.select'),"imgName":'PriceQuotations.svg'},on:{"changeValue":function($event){_vm.priceQuotationJobOrder.priceQuotationToken = $event}}}):_c('DataLabelGroup',{attrs:{"className":'col-md-12',"value":_vm.priceQuotationJobOrder.priceQuotationInfoData
              .priceQuotationNameCurrent,"title":_vm.$t('PriceQuotations.name'),"imgName":'PriceQuotations.svg'}}),_c('CustomSelectBoxMultiple',{attrs:{"className":'col-md-12',"id":"userTokenList","value":_vm.priceQuotationJobOrder.userTokenList,"options":_vm.userTokenOptions,"title":_vm.$t('Users.select'),"imgName":'user.svg',"returnArrayOfObjects":false},on:{"changeValue":function($event){_vm.priceQuotationJobOrder.userTokenList = $event}}}),_c('CustomSelectBox',{attrs:{"className":'col-md-12',"id":'jobOrderStages',"value":'',"options":_vm.jobOrderStageOptions,"title":_vm.$t('JobOrderStages.select'),"imgName":'jobOrderStages.svg'},on:{"changeValue":function($event){return _vm.optionsListHandeler($event)}}}),(_vm.sortList.length)?_c('CustomSortable',{attrs:{"className":'col-md-12',"list":_vm.sortList}}):_vm._e()],1)]),_c('div',{staticClass:"form-actions"},[_c('div',{staticClass:"icon-submit",on:{"click":function($event){$event.preventDefault();return _vm.submitForm.apply(null, arguments)}}},[_c('img',{attrs:{"src":require("@/assets/images/check-icon.svg"),"title":_vm.submitName}})]),_c('div',{directives:[{name:"b-modal",rawName:"v-b-modal",value:(("ConfirmCloseSheet-" + _vm.bottomSheetName)),expression:"`ConfirmCloseSheet-${bottomSheetName}`"}],staticClass:"icon-cancel",on:{"click":function($event){$event.preventDefault();}}},[_c('img',{attrs:{"src":require("@/assets/images/cancel-icon.svg"),"title":_vm.$t('cancel')}})])])])])}
var staticRenderFns = []

export { render, staticRenderFns }