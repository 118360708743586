var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.isLoading)?_c('PreLoader'):_vm._e(),_c('CustomBottomSheet',{attrs:{"refName":'PriceQuotationInfo',"size":"xl","headerText":_vm.$t('PriceQuotations.data'),"headerIcon":_vm.priceQuotation.icon},on:{"opened":function($event){return _vm.getDetails()}}},[(_vm.exceptionMsg)?_c('ExceptionWithImg',{attrs:{"msg":_vm.exceptionMsg,"image":_vm.exceptionImg}}):[_c('div',{staticClass:"row"},[_c('DataLabelGroup',{attrs:{"className":'col-md-6',"value":_vm.priceQuotation.fullCode,"title":_vm.$t('PriceQuotations.code'),"imgName":'code.svg'}}),_c('DataLabelGroup',{attrs:{"className":'col-md-6',"value":_vm.priceQuotation.reportTemplateInfoData
              ? _vm.priceQuotation.reportTemplateInfoData
                  .reportTemplateNameCurrent
              : '',"title":_vm.$t('ConstantsListSelect.ReportTemplatesName'),"imgName":'ReportTemplates.svg'}}),_c('DataLabelGroup',{attrs:{"className":'col-md-12',"value":_vm.priceQuotation.requestPlaceInfoData
              ? _vm.priceQuotation.requestPlaceInfoData.placeNameCurrent
              : '',"title":_vm.$t('PriceQuotations.clientName'),"imgName":'places.svg'}}),_c('DataLabelGroup',{attrs:{"className":'col-md-12',"value":_vm.priceQuotation.priceQuotationNotes,"title":_vm.$t('notes'),"imgName":'notes.svg'}})],1),_c('div',{staticClass:"my-card"},[_c('span',{staticClass:"my-card-title"},[_vm._v(_vm._s(_vm.$t("sales.data")))]),_c('SalesDataTable',{attrs:{"SalesData":_vm.priceQuotation.priceQuotationUsersSalesData}})],1),_c('div',{staticClass:"my-card"},[_c('span',{staticClass:"my-card-title"},[_vm._v(_vm._s(_vm.$t("PriceQuotations.details.name")))]),(_vm.priceQuotation.priceQuotationDetails.length == 0)?_c('div',{staticClass:"my-card-no-content"},[_vm._v(" "+_vm._s(_vm.$t("PriceQuotations.details.thereAreNoSlides"))+" ")]):_vm._l((_vm.priceQuotation.priceQuotationDetails),function(section,index){return _c('div',{key:index,staticClass:"my-card"},[_c('span',{staticClass:"my-card-title"},[_vm._v(_vm._s(index + 1))]),_c('div',{staticClass:"row"},[_c('DataLabelGroup',{attrs:{"className":'col-md-6',"value":section.storeItemInfoData
                    ? section.storeItemInfoData.storeItemNameCurrent
                    : '',"title":_vm.$t('StoreItems.name'),"imgName":'StoreItems.svg'}}),_c('DataLabelGroup',{attrs:{"className":'col-md-6',"value":section.priceQuotationDetailsNotes,"title":_vm.$t('notes'),"imgName":'notes.svg'}}),_c('DataLabelGroup',{attrs:{"className":'col-md-4',"value":section.storeItemQuantity,"title":_vm.$t('StoreItems.quantity'),"imgName":'number.svg'}}),_c('DataLabelGroup',{attrs:{"className":'col-md-4',"value":section.affiliateOperationTypeNameCurrent,"title":_vm.$t('PriceQuotations.details.AffiliateOperation'),"imgName":'AffiliateOperationTypes.svg'}}),_c('DataLabelGroup',{attrs:{"className":'col-md-4',"value":section.quotationDetailsHistoryWorkTypeNameCurrent,"title":_vm.$t('status'),"imgName":'type.svg'}}),_c('PropertyTypesInfoTable',{staticClass:"col-12",attrs:{"propertyTypesData":section.storeItemPropertyTypesData,"hideField":true}})],1)])})],2),_c('LastMoveHistory',{attrs:{"lastPriceQuotationHistoryWorkInfoData":_vm.priceQuotation.lastPriceQuotationHistoryWorkInfoData}})]],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }