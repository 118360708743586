var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('DashboardNavbar',{attrs:{"viewType":_vm.generalSetting.priceQuotationJobOrders.viewType,"viewTypeStatus":true,"btnAddTitle":_vm.$t('PriceQuotationJobOrders.add'),"btnAddName":'PriceQuotationJobOrderAdd',"btnAddStatus":_vm.checkPrivilege(_vm.hasPriceQuotationJobOrderAdd()),"filterSheetName":'filterSheet',"filterSheetStatus":true},on:{"updateViewType":function($event){return _vm.updateViewType('priceQuotationJobOrders', $event)}}}),(_vm.isLoading)?_c('PreLoader'):_vm._e(),_c('div',{staticClass:"app-content pagination-with-content"},[_c('PriceQuotationJobOrderTabs',{attrs:{"theFilterData":_vm.priceQuotationJobOrders.filterData,"statistics":_vm.priceQuotationJobOrders.priceQuotationJobOrderStatistics},on:{"search":function($event){return _vm.search($event)},"currentTabUpdated":function($event){_vm.currentTab = $event}}}),(_vm.hasData)?[(
          _vm.generalSetting.priceQuotationJobOrders.viewType == _vm.VIEW_TYPES.table
        )?_c('PriceQuotationJobOrderTable',{attrs:{"priceQuotationJobOrdersData":_vm.priceQuotationJobOrders.priceQuotationJobOrdersData,"defaultImg":_vm.priceQuotationJobOrders.priceQuotationJobOrder.defaultImg,"filterData":_vm.priceQuotationJobOrders.filterData,"hasPriceQuotationJobOrderMoveTo":_vm.hasPriceQuotationJobOrderMoveTo,"showEditIcon":_vm.showEditIcon},on:{"setPriceQuotationJobOrderData":function($event){return _vm.priceQuotationJobOrders.priceQuotationJobOrder.fillData($event)}}}):_vm._e(),(
          _vm.generalSetting.priceQuotationJobOrders.viewType == _vm.VIEW_TYPES.cards
        )?_c('PriceQuotationJobOrderCards',{attrs:{"priceQuotationJobOrdersData":_vm.priceQuotationJobOrders.priceQuotationJobOrdersData,"defaultImg":_vm.priceQuotationJobOrders.priceQuotationJobOrder.defaultImg,"filterData":_vm.priceQuotationJobOrders.filterData,"hasPriceQuotationJobOrderMoveTo":_vm.hasPriceQuotationJobOrderMoveTo,"showEditIcon":_vm.showEditIcon},on:{"setPriceQuotationJobOrderData":function($event){return _vm.priceQuotationJobOrders.priceQuotationJobOrder.fillData($event)}}}):_vm._e(),_c('PriceQuotationInfo',{attrs:{"token":_vm.priceQuotationJobOrders.priceQuotationJobOrder
            .priceQuotationInfoData.priceQuotationToken}}),_c('PriceQuotationJobOrderInfo',{attrs:{"priceQuotationJobOrder":_vm.priceQuotationJobOrders.priceQuotationJobOrder,"showActions":_vm.showActions},on:{"setJobOrderStagesOfWorkData":function($event){return _vm.jobOrderStagesOfWorks.jobOrderStagesOfWork.fillData($event)}}}),_c('CustomBottomSheetQRCode',{attrs:{"refName":'PriceQuotationJobOrderQRCode',"code":_vm.priceQuotationJobOrders.priceQuotationJobOrder.fullCode,"codeTitle":_vm.$t('PriceQuotationJobOrders.code'),"name":_vm.priceQuotationJobOrders.priceQuotationJobOrder
            .priceQuotationJobOrderNameCurrent,"nameTitle":_vm.$t('general.name'),"nameIcon":'PriceQuotationJobOrders.svg'}}),_c('PriceQuotationJobOrderMove',{attrs:{"currentTab":_vm.currentTab,"priceQuotationJobOrder":_vm.priceQuotationJobOrders.priceQuotationJobOrder,"allawedMoveToTokens":_vm.allawedMoveToTokens},on:{"refresh":function($event){return _vm.refresh()}}}),_c('PriceQuotationReport',{attrs:{"token":_vm.priceQuotationJobOrders.priceQuotationJobOrder
            .priceQuotationInfoData.priceQuotationToken,"exportStatus":true},on:{"isLoading":function($event){_vm.isLoading = $event}}}),_c('PriceQuotationJobOrderReportExport',{attrs:{"token":_vm.priceQuotationJobOrders.priceQuotationJobOrder
            .priceQuotationJobOrderToken},on:{"isLoading":function($event){_vm.isLoading = $event}}}),_c('PriceQuotationJobOrderReportLpo',{attrs:{"token":_vm.priceQuotationJobOrders.priceQuotationJobOrder
            .priceQuotationInfoData.priceQuotationToken},on:{"isLoading":function($event){_vm.isLoading = $event}}})]:_vm._e(),(_vm.exceptionMsg)?_c('ExceptionWithImg',{attrs:{"msg":_vm.exceptionMsg,"image":_vm.exceptionImg}}):_vm._e(),(_vm.hasData)?_c('CustomPagination',{attrs:{"paginationData":_vm.priceQuotationJobOrders.filterData},on:{"changePagination":function($event){return _vm.changePagination($event)}}}):_vm._e(),_c('PriceQuotationJobOrderFilter',{attrs:{"theFilterData":_vm.priceQuotationJobOrders.filterData},on:{"search":function($event){return _vm.search($event)},"isLoading":function($event){_vm.isLoading = $event}}}),_c('PriceQuotationJobOrderAdd',{attrs:{"priceQuotationJobOrder":_vm.priceQuotationJobOrders.priceQuotationJobOrder},on:{"refresh":function($event){return _vm.refresh()}}}),_c('PriceQuotationJobOrderUpdate',{attrs:{"priceQuotationJobOrder":_vm.priceQuotationJobOrders.priceQuotationJobOrder},on:{"refresh":function($event){return _vm.refresh()}}}),_c('JobOrderStagesOfWorkAdd',{attrs:{"jobOrderStagesOfWork":_vm.jobOrderStagesOfWorks.jobOrderStagesOfWork},on:{"getDetails":function($event){return _vm.getDetails($event)}}}),_c('JobOrderStagesOfWorkFinish',{attrs:{"jobOrderStagesOfWork":_vm.jobOrderStagesOfWorks.jobOrderStagesOfWork},on:{"getDetails":function($event){return _vm.getDetails($event)}}}),_c('JobOrderStagesOfWorkCancelFinish',{attrs:{"jobOrderStagesOfWork":_vm.jobOrderStagesOfWorks.jobOrderStagesOfWork},on:{"getDetails":function($event){return _vm.getDetails($event)}}}),_c('JobOrderStagesOfWorkDelete',{attrs:{"jobOrderStagesOfWork":_vm.jobOrderStagesOfWorks.jobOrderStagesOfWork},on:{"getDetails":function($event){return _vm.getDetails($event)}}})],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }